@import "./node_modules/athlinks-static-resources/dist/css/fonts";
@import "./node_modules/athlinks-static-resources/dist/scss/colors.scss";
@import "./node_modules/foundation-sites/scss/foundation";
@import "./node_modules/react-vis/dist/style";

@import "images";

@include foundation-everything;

p {
  font-size: 1rem;
  line-height: 1.3rem;
}

li {
  list-style: none;
}

a {
  color: white;
  opacity: .75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

// Overwrites default react-select placeholder style
.Select-placeholder {
  font-size: 1.2rem;
  top: .7rem;
}

body {
  position: relative;
  color: $gray-dark;
  height: 100vh;
  background-color: $background-gray;
  font-family: 'ProximaNova';
}

.container {
  margin: 5%;
}

hr {
  border-color: $horizontal-divider-gray;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0
}

h1 {
  color: #4A4A4A;
  font-weight: normal;
  font-size: 2rem;
}

h2 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: $black;
}

h3 {
  margin: 0;
  font-size: .9rem;
  font-weight: 700;
  color: $orange;
}

h4 {
  margin: 0;
  font-size: .7rem;
  font-weight: 700;
  color: $lighter-steel-gray;
  letter-spacing: .03rem;
}

h5 {
  font-weight: 600;
  font-size: 1.2rem;
}

h6 {
  margin: 0;
  font-size: .7rem;
  font-weight: 400;
  color: #fff;
}

input[type=text] {
  background-color: #FBFBFB;
  border-radius: .25rem;
}

input[type=password] {
  background-color: #FBFBFB;
  border-radius: .25rem;
}

label {
  color: #4A4A4A;
  font-size: .8rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.callout.alert {
  border-color: #C70000;
  color: #C70000;
}

.textCenter {
  text-align: center
}

.inputError[type=text] {
  background-color: #f7e4e1;
  border-color: #C70000;
  color: #C70000;
}

.inputError[type=password] {
  background-color: #f7e4e1;
  border-color: #C70000;
  color: #C70000;
}

.errorText {
  color: #C70000;
}

.modalBackdrop {
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 400ms;
  z-index: 10;
}

.modalButtonsMobile {
  position: absolute;
  right: 1.25rem;
  bottom: 3.75rem;
  left: 1.25rem;
}

.modalContainer {
  background-color: $white;
  padding: 2.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  margin: auto;
  width: 35rem;
  border-radius: .3125rem;
  box-shadow: 0 0 2.8125rem 0 rgba(0,0,0,0.40);
  animation-duration: 250ms;
  animation-name: fadeIn;
  animation-timing-function: ease-out;
}

.modalContainerMobile {
  animation-duration: 250ms;
  animation-name: fadeIn;
  animation-timing-function: ease-out;
  background-color: $white;
  height: 100%;
  padding: 1.25rem;
  position: relative;
  text-align: center;
}

.modalContentMobile {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.modalErrorSummary {
  border-radius: .1875rem;
  background-color: #CA504C;
  color: #fff;
  text-align: center;
  padding: .75rem
}

.clearButton {
  cursor: pointer;
  border: .0625rem solid #DDDDDD;
  width: 100%;
  font-family: "ProximaNova";
  font-size: .875rem;
  font-weight: bold;
  line-height: .975rem;
  padding: .875rem;
  font-size: .875rem;
  color: $black;
  border-radius: .25rem;
}