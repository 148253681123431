:local {
  .finalTimeHeader {
    padding-top: 40px;
    font-weight: bold
  }
  
  .timeHeader {
    font-size: 55px;
    line-height: 55px;
    font-weight: bold;
  }
  
  .timeAgoHeader {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.58px;
  }

  .buttonsHeader {
    margin-top: 40px;
  }
}