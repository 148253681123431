@import "../../index.scss";

:local {
  .athlinks-flame {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .footer-container {
    background-color: $dark-blue;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .grid-container {
    display: table-cell;
    height: 85vh;
    vertical-align: middle;
  }

  .grid-container-wrapper {
    display: table;
    width: 100%;
  }

  .home-header {
    align-items: center;
    background-color: $athlinks-blue;
    background-size: cover;
    color: white;
    min-height: 95vh;
    text-align: center;
  }

  .home-title {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.4375rem;
  }
}