@import "../../index.scss";

:local {
  tr td {
    background-color: $white;
    padding-top: .625rem;
  }

  td:nth-child(1) {
    padding-right: .3125rem;
    text-align: center;
    width: 25%;
  }

  td:nth-child(2) {
    width: 75%;
  }

  .completedSegment {
    background-color: $athlinks-blue;
    border-radius: .3125rem;
    height: .625rem;
    width: 100%;
  }

  .container {
    background-color: $white;
    border-radius: .1875rem;
    color: $black;
    font-size: .875rem;
    line-height: 1.1875rem;
    padding-bottom: .15625rem;
    padding-top: .9375rem;
    text-align: left;
    width: 100%;
  }

  .currentSegment {
    border-radius: .3125rem;
  }

  .mapInformationHeader {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-bottom: 2.3125rem;
    text-align: center;
  }

  .passedSplitArrow {
    border-bottom: .6875rem solid transparent;
    border-right: .4375rem solid rgb(162, 168, 192);
    border-top: .6875rem solid transparent;
    float: left;
    margin-top: 0;
    opacity: .75;
  }

  .selectedRunner {
    background-color: $athlinks-blue;
    border: .125rem solid $dark-blue;
    border-radius: 5.625rem;
    box-sizing: border-box;
    color: white;
    display: inline-block;
    font-weight: bold;
    height: 1.875rem;
    padding-top: .25rem;
    text-align: center;
    width: 1.875rem;
  }

  .splitName {
    border: .125rem solid $dark-blue;
    font-weight: bold;
    margin-left: .4375rem;
    padding-left: .3125rem;
    padding-right: .3125rem;
    text-align: center;
  }

  .table {
    border-collapse: collapse;
    margin: 0;
  }

  .tableBody {
    border: 0;
  }

  .upcomingSegment {
    background-color: $horizontal-divider-gray;
    border-radius: .3125rem;
    height: .5rem;
    width: 100%;
  }
}
