@import "../../index.scss";

:local {
  .map-error {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }

  .footer-container {
    background-color: $dark-blue;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .grid-container {
    display: table-cell;
    height: 85vh;
    vertical-align: middle;
  }

  .grid-container-wrapper {
    display: table;
    width: 100%;
  }

  .error-header {
    align-items: center;
    background-color: white;
    background-size: cover;
    color: #4A4A4A;
    min-height: 95vh;
    text-align: center;
  }

  .error-title {
    color: #4A4A4A;
    font-size: 2rem;
    line-height: 2.4375rem;
  }

  .error-subtitle {
    color: #4A4A4A;
    margin-top: 1rem;
  }

  .go-back-btn-container {
    margin: 6rem auto;
    width: 30vh;
  }
}