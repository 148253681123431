:local {
  .closeX {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }

  .detailsHeader {
    color: #4A4A4A;
    font-size: .875rem;
    line-height: 1.1875rem;
    padding-top: .3125rem;
  }

  .mainHeader {
    color: #4A4A4A;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  .textCenter {
    text-align: center
  }

  .viewMapBtnContainer {
    padding-right: .625rem;
  }

  .viewResultsBtnContainer {
    padding-left: .625rem;
  }
}