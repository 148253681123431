@import "../../index.scss";

:local {
  .athlete-info {
    float: left;
    height: 100%;
    width: 25rem;
  }

  .closeX {
    position: absolute;
    top: .9375rem;
    right: .625rem;
    z-index: 1;
  }

  .container {
    background-color: $athlinks-blue;
    height: 100vh;
    position: relative;
    width: 100%;
  }

  .doneButton {
    position: absolute;
    right: 1.25rem;
    bottom: 3.75rem;
    left: 1.25rem;
  }

  .fullScreen {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .legend {
    position: absolute;
    bottom: 8.4375rem;
    right: 2.8125rem;
    width: 20.875rem;
  }

  .loading-container {
    align-items: center;
    background-color: $athlinks-blue;
    height: 100vh;
    text-align: center;
    width: 100%;
  }

  .mapContainer {
    float: right;
    height: 100%;
    width: calc(100% - 25rem);
  }

  .mobileAthleteContainer {
    height: 100%;
    width: 100%;
  }

  .mobileMapContainer {
    height: 100%;
    width: 100%;
  }

  .text {
    color: $white;
    margin: auto;
  }

  .athlinksLogoContainer {
    position: absolute;
    top: 1.5625rem;
    right: 1.5625rem;
  }

  .splitPaneResizer {
    background: lightgray;
    border: 6px solid white;
    border-left-width: 20vh;
    border-right-width: 20vh;
    cursor: row-resize;
    height: 20px;
    padding: 3px;
    width: 100%;
    z-index: 1;
  }
}