:local {

  .changedCourseHeader {
    color: #4A4A4A;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .changedCourseBody {
    margin-top: 1.5rem;
    font-size: 0.9rem;
  }
  
  .spacer {
    height: 5rem
  }
    
  .buttonsHeader {
    margin-top: 8rem;
  }
}
