@import "../../index.scss";

.split-context-popup {
  opacity: 30%;
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-content {
    background-color: transparent;
    padding: 0;
  }
}
