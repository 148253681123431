@import "../../index.scss";

:local {
  .container {
    background-color: $athlinks-blue;
    height: 100vh;
    position: relative;
    width: 100%;
  }

  .fullScreen {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .legend {
    position: absolute;
    bottom: 8.4375rem;
    right: 2.8125rem;
    width: 20.875rem;
  }

  .loading-container {
    align-items: center;
    background-color: $athlinks-blue;
    height: 100vh;
    text-align: center;
    width: 100%;
  }

  .mapContainer {
    height: 100%;
    width: 100%;
  }

  .text {
    color: $white;
    margin: auto;
  }

  .athlinksLogoContainer {
    position: absolute;
    top: 1.5625rem;
    right: 1.5625rem;
  }
}
