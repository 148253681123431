@import "../../index.scss";

:local {
  .athlete-info {
    float: left;
    height: 100%;
    width: 400px;
  }
  .container {
    background-color: $athlinks-blue;
    height: 100vh;
    width: 100%;
  }
  .map-container {
    align-items: center;
    text-align: center;
  }
}