@import "../../index.scss";

:local {
  .centerBtn {
    border-radius: .3125rem;
    bottom: 6.25rem;
    padding-top: .3125rem;
  }

  .centerBtn, .zoomInBtn, .zoomOutBtn {
    background-color: $white;
    color: $black;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    line-height: normal;
    padding-bottom: .125rem;
    padding-top: .125rem;
    position: absolute;
    right: .625rem;
    text-align: center;
    width: 1.5625rem;
  }

  .container {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .legendToggle {
    background-color: $white;
    border-radius: .3125rem;
    color: $black;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: normal;
    padding: 0rem .3125rem;
    text-align: center;
    width: 1.5625rem;
    position: absolute;
    bottom: 8.4375rem;
    right: .625rem;
  }

  .mapBoxContainer {
    display: flex;
    height: 100%;
    text-align: justify
  }

  .zoomInBtn {
    border-radius: .3125rem .3125rem 0 0;
    bottom: 4.0625rem;
  }

  .zoomOutBtn {
    border-radius: 0 0 .3125rem .3125rem;
    bottom: 2.5rem;
  }
}

.finishIndicator {
  background-color: $error-red;
  border: .125rem solid $dark-blue;
  border-radius: .3125rem;
  color: $white;
  font-weight: bold;
  padding: .1875rem;
  opacity: .75;
  text-align: center;
}

.initialsBadge {
  background-color: $athlinks-blue;
  border-radius: 5.625rem;
  border: .3125rem solid $dark-blue;
  color: $white;
  font-size: 1.3rem;
  font-weight: bold;
  height: 3.125rem;
  opacity: .9;
  padding-top: .625rem;
  position: absolute;
  top: -.3125rem;
  left: 12.5rem;
  text-align: center;
  width: 3.125rem;
}

.passedSplitArrowIcon {
  border-bottom: 1.125rem solid transparent;
  border-right: .625rem solid $white;
  border-top: 1.125rem solid transparent;
  float: left;
  height: 0;
  margin-top: 0;
  opacity: .75;
  width: 0;
}

.passedSplitIntervalSummary {
  background-color: $white;
  color: $black;
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: .625rem;
  opacity: .75;
  overflow-x: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 12.5rem;
  height: 2.25rem;
  padding-top: 0.375rem;
}

.passedSplitTimeSummary {
  background-color: $dark-blue;
  color: $white;
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: .75;
  padding: 0 .3125rem;
  text-align: center;
}

.startIndicator {
  background-color: $success-green;
  border: .125rem solid $dark-blue;
  border-radius: .3125rem;
  color: $white;
  font-weight: bold;
  opacity: .75;
  padding: .1875rem;
  text-align: center;
}

