:local {
  .buttonsHeader {
    margin-top: 80px;
  }

  .startTimeText {
    padding-top: 40px;
    font-weight: bold
  }

  .countDown {
    font-size: 55px;
    font-weight: bold;
  }

  .countDownBorderRight {
    font-size: 55px;
    font-weight: bold;
    border-right: 1px solid #DDDDDD;
  }

  .coundDownLabel {
    color: #9B9B9B;
    font-size: 12px;
  }
}