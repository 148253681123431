@import "../../index.scss";

:local {
  .athlete-info-container {
    border-bottom: 1px solid $horizontal-divider-gray;
    padding-bottom: 1rem;
  }
  .bib-number {
    @extend .sub-text;
    display: inline-block;
    margin-right: .5rem;
  }
  .container {
    background-color: white;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 1.25rem;
    width: 100%;
  }
  .course-name {
    border: 1px solid $horizontal-divider-gray;
    border-radius: .625rem;
    color: $steel-gray;
    display: inline-block;
    font-family: ProximaNova;
    font-size: .625rem;
    font-weight: bold;
    letter-spacing: .03125rem;
    line-height: 1rem;
    padding-left: .625rem;
    padding-right: .625rem;
    text-transform: uppercase;
  }
  .inline-block {
    display: inline-block;
  }
  .name-icon {
    background-color: $athlinks-blue;
    border: 1px solid $dark-blue;
    border-radius: 1rem;
    color: $white;
    float: left;
    font-size: 1rem;
    font-weight: 800;
    height: 2rem;
    line-height: 2rem;
    margin-right: .375rem;
    text-align: center;
    width: 2rem;
  }
  .name-text {
    color: $black;
    font-family: ProximaNova;
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.125rem;
    margin-bottom: .5rem;
  }
  .pace-container {
    line-height: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .pace-time {
    color: $black;
    display: inline-block;
    font-size: .75rem;
    margin-right: .25rem;
  }
  .pace-unit {
    color: $steel-gray;
    display: inline-block;
    font-size: .75rem;
  }
  .pizza-tracker-container {
    margin-bottom: 2.125rem;
    margin-top: 1rem;
  }
  .progress-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .split-column {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin: auto;
  }
  .split-container {
    margin-top: 1.75rem;
  }
  .split-header {
    @extend .split-row;
    background-color: $background-gray;
    border-top: 1px solid $ellipsis-gray;
  }
  .split-header-text {
    color: $steel-gray;
    font-family: ProximaNova;
    font-size: .6875rem;
    font-weight: bold;
    letter-spacing: .03125rem;
    line-height: .8125rem;
    text-transform: uppercase;
  }
  .split-header-time-of-day {
    @extend .split-time-of-day;
    @extend .split-header-text;
    border-left: 1px solid $ellipsis-gray;
    border-right: 1px solid $ellipsis-gray;
  }
  .split-name {
    @extend .split-column;
    flex: 1.25;
  }
  .split-name-text {
    color: $black;
    font-family: ProximaNova;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .split-row {
    border-bottom: 1px solid $ellipsis-gray;
    display: flex;
    flex-direction: row;
    padding-bottom: .75rem;
    padding-left: 1rem;
    padding-top: .75rem;
    width: 100%;
  }
  .split-sub-text {
    color: $steel-gray;
    font-family: ProximaNova;
    font-size: .6875rem;
    line-height: .8125rem;
    text-transform: lowercase;
  }
  .split-time {
    @extend .split-column;
    flex: 1;
    text-align: center;
  }
  .split-time-of-day {
    @extend .split-column;
    flex: 1;
    text-align: center;
  }
  .split-time-text {
    color: $black;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.1875rem;
  }
  .split-title {
    @extend .title;
    padding-bottom: .75rem;
    padding-left: 1rem;
  }
  .sub-text {
    color: $steel-gray;
    font-family: ProximaNova;
    font-size: .75rem;
    line-height: 1rem;
  }
  .sub-text-container {
    line-height: 1rem;
  }
  .time {
    color: $black;
    font-size: 2.125rem;
    font-weight: 900;
    line-height: 2.5625rem;
    margin-top: .75rem;
    text-align: center;
  }
  .title {
    color: $black;
    font-family: ProximaNova;
    font-size: 1rem;
    line-height: 1.0625rem;
  }
}